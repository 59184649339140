/**
 * Global variables
 */
"use strict";

var userAgent = navigator.userAgent.toLowerCase(),
    initialDate = new Date(),

    $document = $(document),
    $window = $(window),
    $html = $("html"),
    $body = $("body"),

    isDesktop = $html.hasClass("desktop"),
    isIE = userAgent.indexOf("msie") != -1 ? parseInt(userAgent.split("msie")[1]) : userAgent.indexOf("trident") != -1 ? 11 : userAgent.indexOf("edge") != -1 ? 12 : false,
    isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
    isTouch = "ontouchstart" in window,
    c3ChartsArray = [],
    onloadCaptchaCallback,
    isNoviBuilder = false,

    plugins = {
        pointerEvents: false,

        bootstrapTooltip: $("[data-toggle='tooltip']"),
        bootstrapTabs: $(".tabs"),


        rdInputLabel: $(".form-label"),
        rdNavbar: $(".rd-navbar"),

        regula: $("[data-constraints]"),
        stepper: $("input[type='number']"),
        radio: $("input[type='radio']"),
        checkbox: $(".checkbox-custom"),
        toggles: $(".toggle-custom"),
        textRotator: $(".text-rotator"),
        owl: $(".owl-carousel"),
        swiper: $(".swiper-slider"),
        counter: $(".counter"),

        flickrfeed: $(".flickr"),

        progressBar: $(".progress-linear"),
        circleProgress: $(".progress-bar-circle"),
        isotope: $(".isotope"),
        countDown: $(".countdown"),
        stacktable: $("table[data-responsive='true']"),
        customToggle: $("[data-custom-toggle]"),
        customWaypoints: $('[data-custom-scroll-to]'),
        resizable: $(".resizable"),
        dateCountdown: $('.DateCountdown'),
        selectFilter: $("select"),
        calendar: $(".rd-calendar"),
        productThumb: $(".product-thumbnails"),
        imgZoom: $(".img-zoom"),

        pageLoader: $(".page-loader"),
        search: $(".rd-search"),
        searchResults: $('.rd-search-results'),
        rdMailForm: $(".rd-mailform"),
        iframeEmbed: $("iframe.embed-responsive-item"),
        bootstrapDateTimePicker: $("[data-time-picker]"),
        checkoutRDTabs: $(".checkout-tabs"),

        higCharts: {
            charts: $(".higchart"),
            legend: $(".chart-legend")
        },
        d3Charts: $('.d3-chart'),
        flotCharts: $('.flot-chart'),
        captcha: $('.recaptcha'),
        galleryRDTabs: $(".gallery-tabs"),
        fullCalendar: $("#calendar"),

        wow: $(".wow"),
        materialParallax: $(".parallax-container"),
        lightGallery: $("[data-lightgallery='group']"),
        lightGalleryItem: $("[data-lightgallery='item']"),
        lightDynamicGalleryItem: $("[data-lightgallery='dynamic']"),
        maps: $(".google-map-container")
    };

/**
 * Initialize All Scripts
 */
$document.ready(function () {
    isNoviBuilder = window.xMode;

    /**
     * isScrolledIntoView
     * @description  check the element whas been scrolled into the view
     */
    function isScrolledIntoView(elem) {
        if (!isNoviBuilder) {
            return elem.offset().top + elem.outerHeight() >= $window.scrollTop() && elem.offset().top <= $window.scrollTop() + $window.height();
        } else {
            return true;
        }
    }


    /**
     * initOnView
     * @description  calls a function when element has been scrolled into the view
     */
    function lazyInit(element, func) {
        var $win = jQuery(window);
        $win.on('load scroll', function () {
            if ((!element.hasClass('lazy-loaded') && (isScrolledIntoView(element)))) {
                func.call(element);
                element.addClass('lazy-loaded');
            }
        });
    }

    /**
     * resizeOnImageLoad
     * @description  calls a resize event when imageloaded
     */
    function resizeOnImageLoad(image) {
        image.onload = function () {
            $window.trigger("resize");
        }
    }


    /**
     * getSwiperHeight
     * @description  calculate the height of swiper slider basing on data attr
     */
    function getSwiperHeight(object, attr) {
        var val = object.attr("data-" + attr),
            dim;

        if (!val) {
            return undefined;
        }

        dim = val.match(/(px)|(%)|(vh)|(vw)$/i);

        if (dim.length) {
            switch (dim[0]) {
                case "px":
                    return parseFloat(val);
                case "vh":
                    return $window.height() * (parseFloat(val) / 100);
                case "vw":
                    return $window.width() * (parseFloat(val) / 100);
                case "%":
                    return object.width() * (parseFloat(val) / 100);
            }
        } else {
            return undefined;
        }
    }


    /**
     * toggleSwiperInnerVideos
     * @description  toggle swiper videos on active slides
     // */
    function toggleSwiperInnerVideos(swiper) {
        var prevSlide = $(swiper.slides[swiper.previousIndex]),
            nextSlide = $(swiper.slides[swiper.activeIndex]),
            videos,
            videoItems = prevSlide.find("video");

        for (var i = 0; i < videoItems.length; i++) {
            videoItems[i].pause();
        }

        videos = nextSlide.find("video");
        if (videos.length) {
            videos.get(0).play();
        }
    }


    /**
     * toggleSwiperCaptionAnimation
     * @description  toggle swiper animations on active slides
     */
    function toggleSwiperCaptionAnimation(swiper) {
        var prevSlide = $(swiper.container).find("[data-caption-animate]"),
            nextSlide = $(swiper.slides[swiper.activeIndex]).find("[data-caption-animate]"),
            delay,
            duration,
            nextSlideItem,
            prevSlideItem;

        for (var i = 0; i < prevSlide.length; i++) {
            prevSlideItem = $(prevSlide[i]);

            prevSlideItem.removeClass("animated")
                .removeClass(prevSlideItem.attr("data-caption-animate"))
                .addClass("not-animated");
        }


        var tempFunction = function (nextSlideItem, duration) {
            return function () {
                nextSlideItem
                    .removeClass("not-animated")
                    .addClass(nextSlideItem.attr("data-caption-animate"))
                    .addClass("animated");
                if (duration) {
                    nextSlideItem.css('animation-duration', duration + 'ms');
                }
            };
        };

        for (var i = 0; i < nextSlide.length; i++) {
            nextSlideItem = $(nextSlide[i]);
            delay = nextSlideItem.attr("data-caption-delay");
            duration = nextSlideItem.attr('data-caption-duration');
            if (!isNoviBuilder) {
                if (delay) {
                    setTimeout(tempFunction(nextSlideItem, duration), parseInt(delay, 10));
                } else {
                    tempFunction(nextSlideItem, duration);
                }

            } else {
                nextSlideItem.removeClass("not-animated")
            }
        }
    }

    /**
     * @desc Initialize the gallery with set of images
     * @param {object} itemsToInit - jQuery object
     * @param {string} addClass - additional gallery class
     */
    function initLightGallery(itemsToInit, addClass) {
        if (!isNoviBuilder) {
            $(itemsToInit).lightGallery({
                thumbnail: $(itemsToInit).attr("data-lg-thumbnail") !== "false",
                selector: "[data-lightgallery='item']",
                autoplay: $(itemsToInit).attr("data-lg-autoplay") === "true",
                pause: parseInt($(itemsToInit).attr("data-lg-autoplay-delay")) || 5000,
                addClass: addClass,
                mode: $(itemsToInit).attr("data-lg-animation") || "lg-slide",
                loop: $(itemsToInit).attr("data-lg-loop") !== "false"
            });
        }
    }

    /**
     * @desc Initialize the gallery with dynamic addition of images
     * @param {object} itemsToInit - jQuery object
     * @param {string} addClass - additional gallery class
     */
    function initDynamicLightGallery(itemsToInit, addClass) {
        if (!isNoviBuilder) {
            $(itemsToInit).on("click", function () {
                $(itemsToInit).lightGallery({
                    thumbnail: $(itemsToInit).attr("data-lg-thumbnail") !== "false",
                    selector: "[data-lightgallery='item']",
                    autoplay: $(itemsToInit).attr("data-lg-autoplay") === "true",
                    pause: parseInt($(itemsToInit).attr("data-lg-autoplay-delay")) || 5000,
                    addClass: addClass,
                    mode: $(itemsToInit).attr("data-lg-animation") || "lg-slide",
                    loop: $(itemsToInit).attr("data-lg-loop") !== "false",
                    dynamic: true,
                    dynamicEl: JSON.parse($(itemsToInit).attr("data-lg-dynamic-elements")) || []
                });
            });
        }
    }

    /**
     * @desc Initialize the gallery with one image
     * @param {object} itemToInit - jQuery object
     * @param {string} addClass - additional gallery class
     */
    function initLightGalleryItem(itemToInit, addClass) {
        if (!isNoviBuilder) {
            $(itemToInit).lightGallery({
                selector: "this",
                addClass: addClass,
                counter: false,
                youtubePlayerParams: {
                    modestbranding: 1,
                    showinfo: 0,
                    rel: 0,
                    controls: 0
                },
                vimeoPlayerParams: {
                    byline: 0,
                    portrait: 0
                }
            });
        }
    }

    /**
     * @desc Initialize owl carousel plugin
     * @param {object} c - carousel jQuery object
     */
    function initOwlCarousel(c) {
        var aliaces = ["-", "-sm-", "-md-", "-lg-", "-xl-", "-xxl-"],
            values = [0, 576, 768, 992, 1200, 1600],
            responsive = {};

        for (var j = 0; j < values.length; j++) {
            responsive[values[j]] = {};
            for (var k = j; k >= -1; k--) {
                if (!responsive[values[j]]["items"] && c.attr("data" + aliaces[k] + "items")) {
                    responsive[values[j]]["items"] = k < 0 ? 1 : parseInt(c.attr("data" + aliaces[k] + "items"), 10);
                }
                if (!responsive[values[j]]["stagePadding"] && responsive[values[j]]["stagePadding"] !== 0 && c.attr("data" + aliaces[k] + "stage-padding")) {
                    responsive[values[j]]["stagePadding"] = k < 0 ? 0 : parseInt(c.attr("data" + aliaces[k] + "stage-padding"), 10);
                }
                if (!responsive[values[j]]["margin"] && responsive[values[j]]["margin"] !== 0 && c.attr("data" + aliaces[k] + "margin")) {
                    responsive[values[j]]["margin"] = k < 0 ? 30 : parseInt(c.attr("data" + aliaces[k] + "margin"), 10);
                }
            }
        }

        // Enable custom pagination
        if (c.attr('data-dots-custom')) {
            c.on("initialized.owl.carousel", function (event) {
                var carousel = $(event.currentTarget),
                    customPag = $(carousel.attr("data-dots-custom")),
                    active = 0;

                if (carousel.attr('data-active')) {
                    active = parseInt(carousel.attr('data-active'), 10);
                }

                carousel.trigger('to.owl.carousel', [active, 300, true]);
                customPag.find("[data-owl-item='" + active + "']").addClass("active");

                customPag.find("[data-owl-item]").on('click', function (e) {
                    e.preventDefault();
                    carousel.trigger('to.owl.carousel', [parseInt(this.getAttribute("data-owl-item"), 10), 300, true]);
                });

                carousel.on("translate.owl.carousel", function (event) {
                    customPag.find(".active").removeClass("active");
                    customPag.find("[data-owl-item='" + event.item.index + "']").addClass("active")
                });
            });
        }

        c.on("initialized.owl.carousel", function () {
            initLightGalleryItem(c.find('[data-lightgallery="item"]'), 'lightGallery-in-carousel');
        });

        c.owlCarousel({
            autoplay: isNoviBuilder ? false : c.attr("data-autoplay") === "true",
            loop: isNoviBuilder ? false : c.attr("data-loop") !== "false",
            items: 1,
            center: c.attr("data-center") === "true",
            dotsContainer: c.attr("data-pagination-class") || false,
            navContainer: c.attr("data-navigation-class") || false,
            mouseDrag: isNoviBuilder ? false : c.attr("data-mouse-drag") !== "false",
            nav: c.attr("data-nav") === "true",
            dots: c.attr("data-dots") === "true",
            dotsEach: c.attr("data-dots-each") ? parseInt(c.attr("data-dots-each"), 10) : false,
            animateIn: c.attr('data-animation-in') ? c.attr('data-animation-in') : false,
            animateOut: c.attr('data-animation-out') ? c.attr('data-animation-out') : false,
            responsive: responsive,
            navText: c.attr("data-nav-text") ? $.parseJSON(c.attr("data-nav-text")) : [],
            navClass: c.attr("data-nav-class") ? $.parseJSON(c.attr("data-nav-class")) : ['owl-prev', 'owl-next']
        });
    }

    /**
     * Live Search
     * @description  create live search results
     */
    function liveSearch(options) {
        $('#' + options.live).removeClass('cleared').html();
        options.current++;
        options.spin.addClass('loading');
        $.get(handler, {
            s: decodeURI(options.term),
            liveSearch: options.live,
            dataType: "html",
            liveCount: options.liveCount,
            filter: options.filter,
            template: options.template
        }, function (data) {
            options.processed++;
            var live = $('#' + options.live);
            if ((options.processed === options.current) && !live.hasClass('cleared')) {
                live.find('> #search-results').removeClass('active');
                live.html(data);
                setTimeout(function () {
                    live.find('> #search-results').addClass('active');
                }, 50);
            }
            options.spin.parents('.rd-search').find('.input-group-addon').removeClass('loading');
        })
    }

    /**
     * attachFormValidator
     * @description  attach form validation to elements
     */
    function attachFormValidator(elements) {
        // Custom validator - phone number
        regula.custom({
            name: 'PhoneNumber',
            defaultMessage: 'Invalid phone number format',
            validator: function () {
                if (this.value === '') return true;
                else return /^(\+\d)?[0-9\-\(\) ]{5,}$/i.test(this.value);
            }
        });

        for (var i = 0; i < elements.length; i++) {
            var o = $(elements[i]), v;
            o.addClass("form-control-has-validation").after("<span class='form-validation'></span>");
            v = o.parent().find(".form-validation");
            if (v.is(":last-child")) o.addClass("form-control-last-child");
        }

        elements.on('input change propertychange blur', function (e) {
            var $this = $(this), results;

            if (e.type !== "blur") if (!$this.parent().hasClass("has-error")) return;
            if ($this.parents('.rd-mailform').hasClass('success')) return;

            if ((results = $this.regula('validate')).length) {
                for (i = 0; i < results.length; i++) {
                    $this.siblings(".form-validation").text(results[i].message).parent().addClass("has-error");
                }
            } else {
                $this.siblings(".form-validation").text("").parent().removeClass("has-error")
            }
        }).regula('bind');

        var regularConstraintsMessages = [
            {
                type: regula.Constraint.Required,
                newMessage: "The text field is required."
            },
            {
                type: regula.Constraint.Email,
                newMessage: "The email is not a valid email."
            },
            {
                type: regula.Constraint.Numeric,
                newMessage: "Only numbers are required"
            },
            {
                type: regula.Constraint.Selected,
                newMessage: "Please choose an option."
            }
        ];


        for (var i = 0; i < regularConstraintsMessages.length; i++) {
            var regularConstraint = regularConstraintsMessages[i];

            regula.override({
                constraintType: regularConstraint.type,
                defaultMessage: regularConstraint.newMessage
            });
        }
    }

    /**
     * isValidated
     * @description  check if all elemnts pass validation
     */
    function isValidated(elements, captcha) {
        var results, errors = 0;

        if (elements.length) {
            for (var j = 0; j < elements.length; j++) {

                var $input = $(elements[j]);
                if ((results = $input.regula('validate')).length) {
                    for (k = 0; k < results.length; k++) {
                        errors++;
                        $input.siblings(".form-validation").text(results[k].message).parent().addClass("has-error");
                    }
                } else {
                    $input.siblings(".form-validation").text("").parent().removeClass("has-error")
                }
            }

            if (captcha) {
                if (captcha.length) {
                    return validateReCaptcha(captcha) && errors === 0
                }
            }

            return errors === 0;
        }
        return true;
    }


    /**
     * validateReCaptcha
     * @description  validate google reCaptcha
     */
    function validateReCaptcha(captcha) {
        var captchaToken = captcha.find('.g-recaptcha-response').val();

        if (captchaToken.length === 0) {
            captcha
                .siblings('.form-validation')
                .html('Please, prove that you are not robot.')
                .addClass('active');
            captcha
                .closest('.form-wrap')
                .addClass('has-error');

            captcha.on('propertychange', function () {
                var $this = $(this),
                    captchaToken = $this.find('.g-recaptcha-response').val();

                if (captchaToken.length > 0) {
                    $this
                        .closest('.form-wrap')
                        .removeClass('has-error');
                    $this
                        .siblings('.form-validation')
                        .removeClass('active')
                        .html('');
                    $this.off('propertychange');
                }
            });

            return false;
        }

        return true;
    }


    /**
     * onloadCaptchaCallback
     * @description  init google reCaptcha
     */
    window.onloadCaptchaCallback = function () {
        for (var i = 0; i < plugins.captcha.length; i++) {
            var $capthcaItem = $(plugins.captcha[i]);

            grecaptcha.render(
                $capthcaItem.attr('id'),
                {
                    sitekey: $capthcaItem.attr('data-sitekey'),
                    size: $capthcaItem.attr('data-size') ? $capthcaItem.attr('data-size') : 'normal',
                    theme: $capthcaItem.attr('data-theme') ? $capthcaItem.attr('data-theme') : 'light',
                    callback: function (e) {
                        $('.recaptcha').trigger('propertychange');
                    }
                }
            );
            $capthcaItem.after("<span class='form-validation'></span>");
        }
    };

    /**
     * parseJSONObject
     * @description  return JSON object witch methods
     */
    function parseJSONObject(element, attr) {
        return JSON.parse($(element).attr(attr), function (key, value) {
            if ((typeof value) === 'string') {
                if (value.indexOf('function') == 0) {
                    return eval('(' + value + ')');
                }
            }
            return value;
        });
    }


    /**
     * makeUniqueRandom
     * @description  make random for gallery tabs
     */
    function makeUniqueRandom(count) {
        if (!uniqueRandoms.length) {
            for (var i = 0; i < count; i++) {
                uniqueRandoms.push(i);
            }
        }
        var index = Math.floor(Math.random() * uniqueRandoms.length);
        var val = uniqueRandoms[index];
        uniqueRandoms.splice(index, 1);
        return val;
    }

    /**
     * makeVisible
     * @description  set class to gallery tabs to make it visible
     */
    function makeVisible(el) {
        var count = el.length,
            k = 0,
            step = 2.5;
        for (var i = 0; i < count; i++) {
            timer = setTimeout(function () {
                var rand = makeUniqueRandom(count);
                el.eq(rand).addClass('visible');
            }, k * 35);
            k += step;
        }
        timer2 = setTimeout(function () {
            el.not('.visible').addClass('visible');
        }, count * step * 35)
    }

    /**
     * makeInVisible
     * @description  set class to gallery tabs to make it invisible
     */
    function makeInvisible() {
        var el = $('.image.visible');
        el.removeClass('visible');
        uniqueRandoms = [];
        clearTimeout(timer);
        clearTimeout(timer2);
    }

    /**
     * IE Polyfills
     * @description  Adds some loosing functionality to IE browsers
     */
    if (isIE) {
        if (isIE < 10) {
            $html.addClass("lt-ie-10");
        }

        if (isIE < 11) {
            if (plugins.pointerEvents) {
                $.getScript(plugins.pointerEvents)
                    .done(function () {
                        $html.addClass("ie-10");
                        PointerEventsPolyfill.initialize({});
                    });
            }
        }

        if (isIE === 11) {
            $("html").addClass("ie-11");
        }

        if (isIE === 12) {
            $("html").addClass("ie-edge");
        }
    }

    /**
     * Google map function for getting latitude and longitude
     */
    function getLatLngObject(str, marker, map, callback) {
        var coordinates = {};
        try {
            coordinates = JSON.parse(str);
            callback(new google.maps.LatLng(
                coordinates.lat,
                coordinates.lng
            ), marker, map)
        } catch (e) {
            map.geocoder.geocode({'address': str}, function (results, status) {
                if (status === google.maps.GeocoderStatus.OK) {
                    var latitude = results[0].geometry.location.lat();
                    var longitude = results[0].geometry.location.lng();

                    callback(new google.maps.LatLng(
                        parseFloat(latitude),
                        parseFloat(longitude)
                    ), marker, map)
                }
            })
        }
    }

    // Swiper
    if (plugins.swiper.length) {
        for (var i = 0; i < plugins.swiper.length; i++) {
            var s = $(plugins.swiper[i]);
            var pag = s.find(".swiper-pagination"),
                next = s.find(".swiper-button-next"),
                prev = s.find(".swiper-button-prev"),
                bar = s.find(".swiper-scrollbar"),
                swiperSlide = s.find(".swiper-slide"),
                autoplay = false;

            for (var j = 0; j < swiperSlide.length; j++) {
                var $this = $(swiperSlide[j]),
                    url;

                if (url = $this.attr("data-slide-bg")) {
                    $this.css({
                        "background-image": "url(" + url + ")",
                        "background-size": "cover"
                    })
                }
            }

            swiperSlide.end()
                .find("[data-caption-animate]")
                .addClass("not-animated")
                .end();

            s.swiper({
                autoplay: !isNoviBuilder && $.isNumeric(s.attr('data-autoplay')) ? s.attr('data-autoplay') : false,
                direction: s.attr('data-direction') ? s.attr('data-direction') : "horizontal",
                effect: s.attr('data-slide-effect') ? s.attr('data-slide-effect') : "slide",
                speed: s.attr('data-slide-speed') ? s.attr('data-slide-speed') : 600,
                keyboardControl: s.attr('data-keyboard') === "true",
                mousewheelControl: s.attr('data-mousewheel') === "true",
                mousewheelReleaseOnEdges: s.attr('data-mousewheel-release') === "true",
                nextButton: next.length ? next.get(0) : null,
                prevButton: prev.length ? prev.get(0) : null,
                pagination: pag.length ? pag.get(0) : null,
                paginationClickable: pag.length ? pag.attr("data-clickable") !== "false" : false,
                paginationBulletRender: pag.length ? pag.attr("data-index-bullet") === "true" ? function (swiper, index, className) {
                    return '<span class="' + className + '">' + (index + 1) + '</span>';
                } : null : null,
                scrollbar: bar.length ? bar.get(0) : null,
                scrollbarDraggable: bar.length ? bar.attr("data-draggable") !== "false" : true,
                scrollbarHide: bar.length ? bar.attr("data-draggable") === "false" : false,
                loop: isNoviBuilder ? false : s.attr('data-loop') !== "false",
                simulateTouch: s.attr('data-simulate-touch') && !isNoviBuilder ? s.attr('data-simulate-touch') === "true" : false,
                onTransitionStart: function (swiper) {
                    toggleSwiperInnerVideos(swiper);
                },
                onTransitionEnd: function (swiper) {
                    toggleSwiperCaptionAnimation(swiper);
                },
                onInit: function (swiper) {
                    toggleSwiperInnerVideos(swiper);
                    toggleSwiperCaptionAnimation(swiper);
                    initLightGalleryItem(s.find('[data-lightgallery="item"]'), 'lightGallery-in-carousel');
                }
            });

            $window.on("resize", (function (s) {
                return function () {
                    var mh = getSwiperHeight(s, "min-height"),
                        h = getSwiperHeight(s, "height");
                    if (h) {
                        s.css("height", mh ? mh > h ? mh : h : h);
                    }
                }
            })(s)).trigger("resize");
        }
    }


    /**
     * Copyright Year
     * @description  Evaluates correct copyright year
     */
    var o = $(".copyright-year");
    if (o.length) {
        o.text(initialDate.getFullYear());
    }


    /**
     * TimeCircles
     * @description  Enable TimeCircles plugin
     */
    /**
     * TimeCircles
     * @description Enable TimeCircles plugin
     */
    if (plugins.dateCountdown.length) {
        var i;
        for (i = 0; i < plugins.dateCountdown.length; i++) {
            var dateCountdownItem = $(plugins.dateCountdown[i]),
                time = {
                    "Days": {
                        "text": "Days",
                        "color": "#ec606a",
                        "bg_width": "0.5",
                        "show": true
                    },
                    "Hours": {
                        "text": "Hours",
                        "color": "#ec606a",
                        "bg_width": "0.5",
                        "show": true
                    },
                    "Minutes": {
                        "text": "Minutes",
                        "color": "#ec606a",
                        "bg_width": "0.5",
                        "show": true
                    },
                    "Seconds": {
                        "text": "Seconds",
                        "color": "#ec606a",
                        "bg_width": "0.5",
                        "show": true
                    }
                };
            dateCountdownItem.TimeCircles({
                "animation": "smooth",
                "bg_width": 1,
                "fg_width": 0.05,
                "circle_bg_color": "#e5e5e5",
                "time": time
            });
            dateCountdownItem.TimeCircles({});
            $(window).on('load resize orientationchange', function () {
                if (window.innerWidth < 479) {
                    dateCountdownItem.TimeCircles({
                        time: {
                            Minutes: {show: true},
                            Seconds: {show: false}
                        }
                    }).rebuild();
                } else if (window.innerWidth < 767) {
                    dateCountdownItem.TimeCircles({
                        time: {
                            Seconds: {show: false}
                        }
                    }).rebuild();
                } else {
                    dateCountdownItem.TimeCircles({time: time}).rebuild();
                }
            });
        }
    }

    /**
     * Circle Progress
     * @description Enable Circle Progress plugin
     */
    if (plugins.circleProgress.length) {
        var i;
        for (i = 0; i < plugins.circleProgress.length; i++) {
            var circleProgressItem = $(plugins.circleProgress[i]);
            $document
                .on("scroll", function () {
                    if (!circleProgressItem.hasClass('animated')) {

                        var arrayGradients = circleProgressItem.attr('data-gradient').split(",");

                        circleProgressItem.circleProgress({
                            value: circleProgressItem.attr('data-value'),
                            size: circleProgressItem.attr('data-size') ? circleProgressItem.attr('data-size') : 175,
                            fill: {gradient: arrayGradients, gradientAngle: Math.PI / 4},
                            startAngle: -Math.PI / 4 * 2,
                            emptyFill: $(this).attr('data-empty-fill') ? $(this).attr('data-empty-fill') : "rgb(245,245,245)",
                            thickness: circleProgressItem.attr('data-thickness') ? parseInt(circleProgressItem.attr('data-thickness')) : 8,


                        }).on('circle-animation-progress', function (event, progress, stepValue) {
                            $(this).find('span').text(String(stepValue.toFixed(2)).replace('0.', '').replace('1.', '1'));
                        });
                        circleProgressItem.addClass('animated');
                    }
                })
                .trigger("scroll");
        }
    }


    /**
     * Progress bar
     * @description  Enable progress bar
     */
    if (plugins.progressBar.length) {
        for (i = 0; i < plugins.progressBar.length; i++) {
            var progressBar = $(plugins.progressBar[i]);
            $window
                .on("scroll load", $.proxy(function () {
                    var bar = $(this);
                    if (!bar.hasClass('animated-first') && isScrolledIntoView(bar)) {
                        var end = bar.attr("data-to");
                        bar.find('.progress-bar-linear').css({width: end + '%'});
                        bar.find('.progress-value').countTo({
                            refreshInterval: 40,
                            from: 0,
                            to: end,
                            speed: 500
                        });
                        bar.addClass('animated-first');
                    }
                }, progressBar));
        }
    }

    /**
     * jQuery Countdown
     * @description  Enable countdown plugin
     */
    if (plugins.countDown.length) {
        var i, j;
        for (i = 0; i < plugins.countDown.length; i++) {
            var countDownItem = plugins.countDown[i],
                $countDownItem = $(countDownItem),
                d = new Date(),
                type = countDownItem.getAttribute('data-type'),
                time = countDownItem.getAttribute('data-time'),
                format = countDownItem.getAttribute('data-format'),
                settings = [];

            d.setTime(Date.parse(time)).toLocaleString();
            settings[type] = d;
            settings['format'] = format;

            if ($countDownItem.parents('.countdown-modern').length) {
                settings['onTick'] = function () {
                    var section = $(this).find(".countdown-section");
                    for (j = 0; j < section.length; j++) {
                        $(section[section.length - j - 1]).append('<span class="countdown-letter">' + format[format.length - j - 1] + '</span>')
                    }
                }
            }

            $countDownItem.countdown(settings);
        }
    }


    /**
     * Bootstrap tabs
     * @description Activate Bootstrap Tabs
     */
    if (plugins.bootstrapTabs.length) {
        var i;
        for (i = 0; i < plugins.bootstrapTabs.length; i++) {
            var bootstrapTab = $(plugins.bootstrapTabs[i]);

            bootstrapTab.on("click", "a", function (event) {
                event.preventDefault();
                $(this).tab('show');
            });
        }
    }

    /**
     * Bootstrap Tooltips
     * @description Activate Bootstrap Tooltips
     */
    if (plugins.bootstrapTooltip.length) {
        plugins.bootstrapTooltip.tooltip();
    }


    // Google maps
    if (plugins.maps.length) {
        $.getScript("//maps.google.com/maps/api/js?key=AIzaSyCSgethDq1zkwGAkYafWILRlVAOLVjgc7Q&sensor=false&libraries=geometry,places&v=3.7", function () {
            var head = document.getElementsByTagName('head')[0],
                insertBefore = head.insertBefore;

            head.insertBefore = function (newElement, referenceElement) {
                if (newElement.href && newElement.href.indexOf('//fonts.googleapis.com/css?family=Roboto') !== -1 || newElement.innerHTML.indexOf('gm-style') !== -1) {
                    return;
                }
                insertBefore.call(head, newElement, referenceElement);
            };
            var geocoder = new google.maps.Geocoder;
            for (var i = 0; i < plugins.maps.length; i++) {
                var zoom = parseInt(plugins.maps[i].getAttribute("data-zoom"), 10) || 11;
                var styles = plugins.maps[i].hasAttribute('data-styles') ? JSON.parse(plugins.maps[i].getAttribute("data-styles")) : [];
                var center = plugins.maps[i].getAttribute("data-center") || "New York";

                // Initialize map
                var map = new google.maps.Map(plugins.maps[i].querySelectorAll(".google-map")[0], {
                    zoom: zoom,
                    styles: styles,
                    scrollwheel: false,
                    center: {lat: 0, lng: 0}
                });
                // Add map object to map node
                plugins.maps[i].map = map;
                plugins.maps[i].geocoder = geocoder;
                plugins.maps[i].google = google;

                // Get Center coordinates from attribute
                getLatLngObject(center, null, plugins.maps[i], function (location, markerElement, mapElement) {
                    mapElement.map.setCenter(location);
                })

                // Add markers from google-map-markers array
                var markerItems = plugins.maps[i].querySelectorAll(".google-map-markers li");

                if (markerItems.length) {
                    var markers = [];
                    for (var j = 0; j < markerItems.length; j++) {
                        var markerElement = markerItems[j];
                        getLatLngObject(markerElement.getAttribute("data-location"), markerElement, plugins.maps[i], function (location, markerElement, mapElement) {
                            var icon = markerElement.getAttribute("data-icon") || mapElement.getAttribute("data-icon");
                            var activeIcon = markerElement.getAttribute("data-icon-active") || mapElement.getAttribute("data-icon-active");
                            var info = markerElement.getAttribute("data-description") || "";
                            var infoWindow = new google.maps.InfoWindow({
                                content: info
                            });
                            markerElement.infoWindow = infoWindow;
                            var markerData = {
                                position: location,
                                map: mapElement.map
                            }
                            if (icon) {
                                markerData.icon = icon;
                            }
                            var marker = new google.maps.Marker(markerData);
                            markerElement.gmarker = marker;
                            markers.push({markerElement: markerElement, infoWindow: infoWindow});
                            marker.isActive = false;
                            // Handle infoWindow close click
                            google.maps.event.addListener(infoWindow, 'closeclick', (function (markerElement, mapElement) {
                                var markerIcon = null;
                                markerElement.gmarker.isActive = false;
                                markerIcon = markerElement.getAttribute("data-icon") || mapElement.getAttribute("data-icon");
                                markerElement.gmarker.setIcon(markerIcon);
                            }).bind(this, markerElement, mapElement));


                            // Set marker active on Click and open infoWindow
                            google.maps.event.addListener(marker, 'click', (function (markerElement, mapElement) {
                                if (markerElement.infoWindow.getContent().length === 0) return;
                                var gMarker, currentMarker = markerElement.gmarker, currentInfoWindow;
                                for (var k = 0; k < markers.length; k++) {
                                    var markerIcon;
                                    if (markers[k].markerElement === markerElement) {
                                        currentInfoWindow = markers[k].infoWindow;
                                    }
                                    gMarker = markers[k].markerElement.gmarker;
                                    if (gMarker.isActive && markers[k].markerElement !== markerElement) {
                                        gMarker.isActive = false;
                                        markerIcon = markers[k].markerElement.getAttribute("data-icon") || mapElement.getAttribute("data-icon")
                                        gMarker.setIcon(markerIcon);
                                        markers[k].infoWindow.close();
                                    }
                                }

                                currentMarker.isActive = !currentMarker.isActive;
                                if (currentMarker.isActive) {
                                    if (markerIcon = markerElement.getAttribute("data-icon-active") || mapElement.getAttribute("data-icon-active")) {
                                        currentMarker.setIcon(markerIcon);
                                    }

                                    currentInfoWindow.open(map, marker);
                                } else {
                                    if (markerIcon = markerElement.getAttribute("data-icon") || mapElement.getAttribute("data-icon")) {
                                        currentMarker.setIcon(markerIcon);
                                    }
                                    currentInfoWindow.close();
                                }
                            }).bind(this, markerElement, mapElement))
                        })
                    }
                }
            }
        });
    }

    /**
     * RD Flickr Feed
     * @description Enables RD Flickr Feed plugin
     */
    if (plugins.flickrfeed.length > 0) {
        var i;
        for (i = 0; i < plugins.flickrfeed.length; i++) {
            var flickrfeedItem = $(plugins.flickrfeed[i]);
            flickrfeedItem.RDFlickr({
                callback: function () {
                    var items = flickrfeedItem.find("[data-photo-swipe-item]");

                    if (items.length) {
                        for (var j = 0; j < items.length; j++) {
                            var image = new Image();
                            image.setAttribute('data-index', j);
                            image.onload = function () {
                                items[this.getAttribute('data-index')].setAttribute('data-size', this.naturalWidth + 'x' + this.naturalHeight);
                            };
                            image.src = items[j].getAttribute('href');
                        }
                    }
                }
            });
        }
    }


    /**
     * RD Input Label
     * @description Enables RD Input Label Plugin
     */
    if (plugins.rdInputLabel.length) {
        plugins.rdInputLabel.RDInputLabel();
    }


    /**
     * Stepper
     * @description Enables Stepper Plugin
     */
    if (plugins.stepper.length) {
        plugins.stepper.stepper({
            labels: {
                up: "",
                down: ""
            }
        });
    }

    /**
     * Radio
     * @description Add custom styling options for input[type="radio"]
     */
    if (plugins.radio.length) {
        var i;
        for (i = 0; i < plugins.radio.length; i++) {
            var $this = $(plugins.radio[i]);
            $this.addClass("radio-custom").after("<span class='radio-custom-dummy'></span>")
        }
    }

    /**
     * Checkbox
     * @description Add custom styling options for input[type="checkbox"]
     */
    if (plugins.checkbox.length) {
        var i;
        for (i = 0; i < plugins.checkbox.length; i++) {
            var $this = $(plugins.checkbox[i]);
            $this.after("<span class='checkbox-custom-dummy'></span>")
        }
    }


    /**
     * Toggles
     * @description Make toggles from input[type="checkbox"]
     */
    if (plugins.toggles.length) {
        var i;
        for (i = 0; i < plugins.toggles.length; i++) {
            var $this = $(plugins.toggles[i]);
            $this.after("<span class='toggle-custom-dummy'></span>")
        }
    }


    /**
     * Regula
     * @description Enables Regula plugin
     */
    if (plugins.regula.length) {
        attachFormValidator(plugins.regula);
    }


    // lightGallery
    if (plugins.lightGallery.length) {
        for (var i = 0; i < plugins.lightGallery.length; i++) {
            initLightGallery(plugins.lightGallery[i]);
        }
    }

    // lightGallery item
    if (plugins.lightGalleryItem.length) {
        // Filter carousel items
        var notCarouselItems = [];

        for (var z = 0; z < plugins.lightGalleryItem.length; z++) {
            if (!$(plugins.lightGalleryItem[z]).parents('.owl-carousel').length &&
                !$(plugins.lightGalleryItem[z]).parents('.swiper-slider').length &&
                !$(plugins.lightGalleryItem[z]).parents('.slick-slider').length) {
                notCarouselItems.push(plugins.lightGalleryItem[z]);
            }
        }

        plugins.lightGalleryItem = notCarouselItems;

        for (var i = 0; i < plugins.lightGalleryItem.length; i++) {
            initLightGalleryItem(plugins.lightGalleryItem[i]);
        }
    }

    // Dynamic lightGallery
    if (plugins.lightDynamicGalleryItem.length) {
        for (var i = 0; i < plugins.lightDynamicGalleryItem.length; i++) {
            initDynamicLightGallery(plugins.lightDynamicGalleryItem[i]);
        }
    }

    // WOW
    if ($html.hasClass("wow-animation") && plugins.wow.length && !isNoviBuilder && isDesktop) {
        new WOW().init();
    }


    /**
     * Text Rotator
     * @description Enables Text Rotator plugin
     */
    if (plugins.textRotator.length) {
        var i;
        for (i = 0; i < plugins.textRotator.length; i++) {
            var textRotatorItem = $(plugins.textRotator[i]);
            textRotatorItem.rotator();
        }
    }

    /**
     * jQuery Count To
     * @description Enables Count To plugin
     */
    if (plugins.counter.length) {
        var i;
        for (i = 0; i < plugins.counter.length; i++) {
            var counterItem = $(plugins.counter[i]);

            $window.on("scroll load", $.proxy(function () {
                var counter = $(this);
                if ((!counter.hasClass("animated-first")) && (isScrolledIntoView(counter))) {
                    counter.countTo({
                        refreshInterval: 40,
                        speed: counter.attr("data-speed") || 1000
                    });
                    counter.addClass('animated-first');
                }
            }, counterItem))
        }
    }


    // Owl carousel
    if (plugins.owl.length) {
        for (var i = 0; i < plugins.owl.length; i++) {
            var c = $(plugins.owl[i]);
            plugins.owl[i].owl = c;

            initOwlCarousel(c);
        }
    }

    // Isotope
    if (plugins.isotope.length) {
        var isogroup = [];
        for (var i = 0; i < plugins.isotope.length; i++) {
            var isotopeItem = plugins.isotope[i],
                isotopeInitAttrs = {
                    itemSelector: '.isotope-item',
                    layoutMode: isotopeItem.getAttribute('data-isotope-layout') ? isotopeItem.getAttribute('data-isotope-layout') : 'masonry',
                    filter: '*'
                };

            if (isotopeItem.getAttribute('data-column-width')) {
                isotopeInitAttrs.masonry = {
                    columnWidth: parseFloat(isotopeItem.getAttribute('data-column-width'))
                };
            } else if (isotopeItem.getAttribute('data-column-class')) {
                isotopeInitAttrs.masonry = {
                    columnWidth: isotopeItem.getAttribute('data-column-class')
                };
            }

            var iso = new Isotope(isotopeItem, isotopeInitAttrs);
            isogroup.push(iso);
        }


        setTimeout(function () {
            for (var i = 0; i < isogroup.length; i++) {
                isogroup[i].element.className += " isotope--loaded";
                isogroup[i].layout();
            }
        }, 200);

        var resizeTimout;

        $("[data-isotope-filter]").on("click", function (e) {
            e.preventDefault();
            var filter = $(this);
            clearTimeout(resizeTimout);
            filter.parents(".isotope-filters").find('.active').removeClass("active");
            filter.addClass("active");
            var iso = $('.isotope[data-isotope-group="' + this.getAttribute("data-isotope-group") + '"]'),
                isotopeAttrs = {
                    itemSelector: '.isotope-item',
                    layoutMode: iso.attr('data-isotope-layout') ? iso.attr('data-isotope-layout') : 'masonry',
                    filter: this.getAttribute("data-isotope-filter") === '*' ? '*' : '[data-filter*="' + this.getAttribute("data-isotope-filter") + '"]'
                };
            if (iso.attr('data-column-width')) {
                isotopeAttrs.masonry = {
                    columnWidth: parseFloat(iso.attr('data-column-width'))
                };
            } else if (iso.attr('data-column-class')) {
                isotopeAttrs.masonry = {
                    columnWidth: iso.attr('data-column-class')
                };
            }
            iso.isotope(isotopeAttrs);
        }).eq(0).trigger("click")
    }


    // lightGallery
    if (plugins.lightGallery.length) {
        for (var i = 0; i < plugins.lightGallery.length; i++) {
            initLightGallery(plugins.lightGallery[i]);
        }
    }

    // lightGallery item
    if (plugins.lightGalleryItem.length) {
        // Filter carousel items
        var notCarouselItems = [];

        for (var z = 0; z < plugins.lightGalleryItem.length; z++) {
            if (!$(plugins.lightGalleryItem[z]).parents('.owl-carousel').length &&
                !$(plugins.lightGalleryItem[z]).parents('.swiper-slider').length &&
                !$(plugins.lightGalleryItem[z]).parents('.slick-slider').length) {
                notCarouselItems.push(plugins.lightGalleryItem[z]);
            }
        }

        plugins.lightGalleryItem = notCarouselItems;

        for (var i = 0; i < plugins.lightGalleryItem.length; i++) {
            initLightGalleryItem(plugins.lightGalleryItem[i]);
        }
    }

    // Dynamic lightGallery
    if (plugins.lightDynamicGalleryItem.length) {
        for (var i = 0; i < plugins.lightDynamicGalleryItem.length; i++) {
            initDynamicLightGallery(plugins.lightDynamicGalleryItem[i]);
        }
    }


    // RD Navbar
    if (plugins.rdNavbar.length) {
        var aliaces, i, j, len, value, values, responsiveNavbar;

        aliaces = ["-", "-sm-", "-md-", "-lg-", "-xl-", "-xxl-"];
        values = [0, 576, 768, 992, 1200, 1600];
        responsiveNavbar = {};

        for (i = j = 0, len = values.length; j < len; i = ++j) {
            value = values[i];
            if (!responsiveNavbar[values[i]]) {
                responsiveNavbar[values[i]] = {};
            }
            if (plugins.rdNavbar.attr('data' + aliaces[i] + 'layout')) {
                responsiveNavbar[values[i]].layout = plugins.rdNavbar.attr('data' + aliaces[i] + 'layout');
            }
            if (plugins.rdNavbar.attr('data' + aliaces[i] + 'device-layout')) {
                responsiveNavbar[values[i]]['deviceLayout'] = plugins.rdNavbar.attr('data' + aliaces[i] + 'device-layout');
            }
            if (plugins.rdNavbar.attr('data' + aliaces[i] + 'hover-on')) {
                responsiveNavbar[values[i]]['focusOnHover'] = plugins.rdNavbar.attr('data' + aliaces[i] + 'hover-on') === 'true';
            }
            if (plugins.rdNavbar.attr('data' + aliaces[i] + 'auto-height')) {
                responsiveNavbar[values[i]]['autoHeight'] = plugins.rdNavbar.attr('data' + aliaces[i] + 'auto-height') === 'true';
            }

            if (isNoviBuilder) {
                responsiveNavbar[values[i]]['stickUp'] = false;
            } else if (plugins.rdNavbar.attr('data' + aliaces[i] + 'stick-up')) {
                responsiveNavbar[values[i]]['stickUp'] = plugins.rdNavbar.attr('data' + aliaces[i] + 'stick-up') === 'true';
            }

            if (plugins.rdNavbar.attr('data' + aliaces[i] + 'stick-up-offset')) {
                responsiveNavbar[values[i]]['stickUpOffset'] = plugins.rdNavbar.attr('data' + aliaces[i] + 'stick-up-offset');
            }
        }


        plugins.rdNavbar.RDNavbar({
            anchorNav: !isNoviBuilder,
            stickUpClone: (plugins.rdNavbar.attr("data-stick-up-clone") && !isNoviBuilder) ? plugins.rdNavbar.attr("data-stick-up-clone") === 'true' : false,
            responsive: responsiveNavbar,
            callbacks: {
                onStuck: function () {
                    var navbarSearch = this.$element.find('.rd-search input');

                    if (navbarSearch) {
                        navbarSearch.val('').trigger('propertychange');
                    }
                },
                onDropdownOver: function () {
                    return !isNoviBuilder;
                },
                onUnstuck: function () {
                    if (this.$clone === null)
                        return;

                    var navbarSearch = this.$clone.find('.rd-search input');

                    if (navbarSearch) {
                        navbarSearch.val('').trigger('propertychange');
                        navbarSearch.trigger('blur');
                    }

                }
            }
        });


        if (plugins.rdNavbar.attr("data-body-class")) {
            document.body.className += ' ' + plugins.rdNavbar.attr("data-body-class");
        }
    }


    /**
     * Stacktable
     * @description Enables Stacktable plugin
     */
    if (plugins.stacktable.length) {
        var i;
        for (i = 0; i < plugins.stacktable.length; i++) {
            var stacktableItem = $(plugins.stacktable[i]);
            stacktableItem.stacktable();
        }
    }

    /**
     * Select2
     * @description Enables select2 plugin
     */
    if (plugins.selectFilter.length) {
        var i;
        for (i = 0; i < plugins.selectFilter.length; i++) {
            var select = $(plugins.selectFilter[i]);

            select.select2({
                theme: "bootstrap"
            }).next().addClass(select.attr("class").match(/(input-sm)|(input-lg)|($)/i).toString().replace(new RegExp(",", 'g'), " "));
        }
    }

    /**
     * Product Thumbnails
     * @description Enables product thumbnails
     */
    if (plugins.productThumb.length) {
        var i;
        for (i = 0; i < plugins.productThumb.length; i++) {
            var thumbnails = $(plugins.productThumb[i]);

            thumbnails.find("li").on('click', function () {
                var item = $(this);
                item.parent().find('.active').removeClass('active');
                var image = item.parents(".product").find(".product-image-area");
                image.removeClass('animateImageIn');
                image.addClass('animateImageOut');
                item.addClass('active');
                setTimeout(function () {
                    var src = item.find("img").attr("src");
                    if (item.attr('data-large-image')) {
                        src = item.attr('data-large-image');
                    }
                    image.attr("src", src);
                    image.removeClass('animateImageOut');
                    image.addClass('animateImageIn');
                }, 300);
            })
        }
    }

    /**
     * RD Calendar
     * @description Enables RD Calendar plugin
     */
    if (plugins.calendar.length) {
        for (i = 0; i < plugins.calendar.length; i++) {
            var calendarItem = $(plugins.calendar[i]);

            calendarItem.rdCalendar({
                days: calendarItem.attr("data-days") ? c.attr("data-days").split(/\s?,\s?/i) : ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
                month: calendarItem.attr("data-months") ? c.attr("data-months").split(/\s?,\s?/i) : ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
            });
        }
    }

    /**
     * jQuery elevateZoom
     * @description Enables jQuery elevateZoom plugin
     */
    if (plugins.imgZoom.length) {
        for (i = 0; i < plugins.imgZoom.length; i++) {
            var zoomItem = $(plugins.imgZoom[i]);

            zoomItem.elevateZoom({
                zoomType: "inner",
                cursor: "crosshair",
                zoomWindowFadeIn: 300,
                zoomWindowFadeOut: 300,
                scrollZoom: true
            });
        }
    }


    /**
     * Page loader
     * @description Enables Page loader
     */
    if (plugins.pageLoader.length > 0) {

        $window.on("load", function () {
            var loader = setTimeout(function () {
                plugins.pageLoader.addClass("loaded");
                $window.trigger("resize");
            }, 200);
        });

    }

    /**
     * RD Search
     * @description Enables search
     */
    if (plugins.search.length || plugins.searchResults) {
        var handler = "bat/rd-search.php";
        var defaultTemplate = '<h5 class="search_title"><a target="_top" href="#{href}" class="search_link">#{title}</a></h5>' +
            '<p>...#{token}...</p>' +
            '<p class="match"><em>Terms matched: #{count} - URL: #{href}</em></p>';
        var defaultFilter = '*.html';

        if (plugins.search.length) {

            for (i = 0; i < plugins.search.length; i++) {
                var searchItem = $(plugins.search[i]),
                    options = {
                        element: searchItem,
                        filter: (searchItem.attr('data-search-filter')) ? searchItem.attr('data-search-filter') : defaultFilter,
                        template: (searchItem.attr('data-search-template')) ? searchItem.attr('data-search-template') : defaultTemplate,
                        live: (searchItem.attr('data-search-live')) ? searchItem.attr('data-search-live') : false,
                        liveCount: (searchItem.attr('data-search-live-count')) ? parseInt(searchItem.attr('data-search-live')) : 4,
                        current: 0, processed: 0, timer: {}
                    };

                if ($('.rd-navbar-search-toggle').length) {
                    var toggle = $('.rd-navbar-search-toggle');
                    toggle.on('click', function () {
                        if (!($(this).hasClass('active'))) {
                            searchItem.find('input').val('').trigger('propertychange');
                        }
                    });
                }

                if (options.live) {
                    searchItem.find('input').on("keyup input propertychange", $.proxy(function () {
                        this.term = this.element.find('input').val().trim();
                        this.spin = this.element.find('.input-group-addon');
                        clearTimeout(this.timer);

                        if (this.term.length > 2) {
                            this.timer = setTimeout(liveSearch(this), 200);
                        } else if (this.term.length == 0) {
                            $('#' + this.live).addClass('cleared').html('');
                        }
                    }, options, this));
                }

                searchItem.submit($.proxy(function () {
                    $('<input />').attr('type', 'hidden')
                        .attr('name', "filter")
                        .attr('value', this.filter)
                        .appendTo(this.element);
                    return true;
                }, options, this))
            }
        }

        if (plugins.searchResults.length) {
            var regExp = /\?.*s=([^&]+)\&filter=([^&]+)/g;
            var match = regExp.exec(location.search);

            if (match != null) {
                $.get(handler, {
                    s: decodeURI(match[1]),
                    dataType: "html",
                    filter: match[2],
                    template: defaultTemplate,
                    live: ''
                }, function (data) {
                    plugins.searchResults.html(data);
                })
            }
        }
    }

    /**
     * UI To Top
     * @description Enables ToTop Button
     */
    if (isDesktop && !isNoviBuilder) {
        $().UItoTop({
            easingType: 'easeOutQuart',
            containerClass: 'ui-to-top icon icon-xs icon-circle icon-darker-filled mdi mdi-chevron-up'
        });
    }

    /**
     * Google ReCaptcha
     * @description Enables Google ReCaptcha
     */
    if (plugins.captcha.length) {
        var i;
        $.getScript("//www.google.com/recaptcha/api.js?onload=onloadCaptchaCallback&render=explicit&hl=en");
    }

    // RD Mailform
    if (plugins.rdMailForm.length) {
        var i, j, k,
            msg = {
                'MF000': 'Successfully sent!',
                'MF001': 'Recipients are not set!',
                'MF002': 'Form will not work locally!',
                'MF003': 'Please, define email field in your form!',
                'MF004': 'Please, define type of your form!',
                'MF254': 'Something went wrong with PHPMailer!',
                'MF255': 'Aw, snap! Something went wrong.'
            };

        for (i = 0; i < plugins.rdMailForm.length; i++) {
            var $form = $(plugins.rdMailForm[i]),
                formHasCaptcha = false;

            $form.attr('novalidate', 'novalidate').ajaxForm({
                data: {
                    "form-type": $form.attr("data-form-type") || "contact",
                    "counter": i
                },
                beforeSubmit: function (arr, $form, options) {
                    if (isNoviBuilder)
                        return;

                    var form = $(plugins.rdMailForm[this.extraData.counter]),
                        inputs = form.find("[data-constraints]"),
                        output = $("#" + form.attr("data-form-output")),
                        captcha = form.find('.recaptcha'),
                        captchaFlag = true;

                    output.removeClass("active error success");

                    if (isValidated(inputs, captcha)) {

                        // veify reCaptcha
                        if (captcha.length) {
                            var captchaToken = captcha.find('.g-recaptcha-response').val(),
                                captchaMsg = {
                                    'CPT001': 'Please, setup you "site key" and "secret key" of reCaptcha',
                                    'CPT002': 'Something wrong with google reCaptcha'
                                };

                            formHasCaptcha = true;

                            $.ajax({
                                method: "POST",
                                url: "bat/reCaptcha.php",
                                data: {'g-recaptcha-response': captchaToken},
                                async: false
                            })
                                .done(function (responceCode) {
                                    if (responceCode !== 'CPT000') {
                                        if (output.hasClass("snackbars")) {
                                            output.html('<p><span class="icon text-middle mdi mdi-check icon-xxs"></span><span>' + captchaMsg[responceCode] + '</span></p>')

                                            setTimeout(function () {
                                                output.removeClass("active");
                                            }, 3500);

                                            captchaFlag = false;
                                        } else {
                                            output.html(captchaMsg[responceCode]);
                                        }

                                        output.addClass("active");
                                    }
                                });
                        }

                        if (!captchaFlag) {
                            return false;
                        }

                        form.addClass('form-in-process');

                        if (output.hasClass("snackbars")) {
                            output.html('<p><span class="icon text-middle fa fa-circle-o-notch fa-spin icon-xxs"></span><span>Sending</span></p>');
                            output.addClass("active");
                        }
                    } else {
                        return false;
                    }
                },
                error: function (result) {
                    if (isNoviBuilder)
                        return;

                    var output = $("#" + $(plugins.rdMailForm[this.extraData.counter]).attr("data-form-output")),
                        form = $(plugins.rdMailForm[this.extraData.counter]);

                    output.text(msg[result]);
                    form.removeClass('form-in-process');

                    if (formHasCaptcha) {
                        grecaptcha.reset();
                    }
                },
                success: function (result) {
                    if (isNoviBuilder)
                        return;

                    var form = $(plugins.rdMailForm[this.extraData.counter]),
                        output = $("#" + form.attr("data-form-output")),
                        select = form.find('select');

                    form
                        .addClass('success')
                        .removeClass('form-in-process');

                    if (formHasCaptcha) {
                        grecaptcha.reset();
                    }

                    result = result.length === 5 ? result : 'MF255';
                    output.text(msg[result]);

                    if (result === "MF000") {
                        if (output.hasClass("snackbars")) {
                            output.html('<p><span class="icon text-middle mdi mdi-check icon-xxs"></span><span>' + msg[result] + '</span></p>');
                        } else {
                            output.addClass("active success");
                        }
                    } else {
                        if (output.hasClass("snackbars")) {
                            output.html(' <p class="snackbars-left"><span class="icon icon-xxs mdi mdi-alert-outline text-middle"></span><span>' + msg[result] + '</span></p>');
                        } else {
                            output.addClass("active error");
                        }
                    }

                    form.clearForm();

                    if (select.length) {
                        select.select2("val", "");
                    }

                    form.find('input, textarea').trigger('blur');

                    setTimeout(function () {
                        output.removeClass("active error success");
                        form.removeClass('success');
                    }, 3500);
                }
            });
        }
    }

    /**
     * Custom Toggles
     */
    if (plugins.customToggle.length) {
        var i;
        for (i = 0; i < plugins.customToggle.length; i++) {
            var $this = $(plugins.customToggle[i]);
            $this.on('click', function (e) {
                e.preventDefault();
                $("#" + $(this).attr('data-custom-toggle')).add(this).toggleClass('active');
            });

            if ($this.attr("data-custom-toggle-disable-on-blur") === "true") {
                $("body").on("click", $this, function (e) {
                    if (e.target !== e.data[0] && $("#" + e.data.attr('data-custom-toggle')).find($(e.target)).length == 0 && e.data.find($(e.target)).length == 0) {
                        $("#" + e.data.attr('data-custom-toggle')).add(e.data[0]).removeClass('active');
                    }
                })
            }
        }
    }

    /**
     * Custom Waypoints
     */
    if (plugins.customWaypoints.length) {
        var i;
        $document.delegate("[data-custom-scroll-to]", "click", function (e) {
            e.preventDefault();
            $("body, html").stop().animate({
                scrollTop: $("#" + $(this).attr('data-custom-scroll-to')).offset().top - 70
            }, 1000, function () {
                $(window).trigger("resize");
            });
        });
    }

    /**
     * Bootstrap Date time picker
     */
    if (plugins.bootstrapDateTimePicker.length) {
        var i;
        for (i = 0; i < plugins.bootstrapDateTimePicker.length; i++) {
            var $dateTimePicker = $(plugins.bootstrapDateTimePicker[i]);
            var options = {};

            options['format'] = 'dddd DD MMMM YYYY - HH:mm';
            if ($dateTimePicker.attr("data-time-picker") == "date") {
                options['format'] = 'dddd DD MMMM YYYY';
                options['minDate'] = new Date();
            } else if ($dateTimePicker.attr("data-time-picker") == "time") {
                options['format'] = 'HH:mm';
            }

            options["time"] = ($dateTimePicker.attr("data-time-picker") != "date");
            options["date"] = ($dateTimePicker.attr("data-time-picker") != "time");
            options["shortTime"] = true;

            $dateTimePicker.bootstrapMaterialDatePicker(options);
        }
    }

    /**
     * Checkout RD Material Tabs
     */
    if (plugins.checkoutRDTabs.length) {
        var i, step = 0;
        for (i = 0; i < plugins.checkoutRDTabs.length; i++) {
            var checkoutTab = $(plugins.checkoutRDTabs[i]);

            checkoutTab.RDMaterialTabs({
                dragList: true,
                dragContent: false,
                items: 1,
                marginContent: 10,
                margin: 0,
                responsive: {
                    480: {
                        items: 2
                    },
                    768: {
                        dragList: false,
                        items: 3
                    }
                },
                callbacks: {
                    onChangeStart: function (active, indexTo) {
                        if (indexTo > step + 1) {
                            return false;
                        } else if (indexTo == step + 1) {
                            for (var j = 0; j < this.$content.find(".rd-material-tab").length; j++) {
                                if (j <= step) {
                                    var inputs = this.$content.find(".rd-material-tab").eq(j).find("[data-constraints]");

                                    if (!isValidated(inputs)) {
                                        this.setContentTransition(this, this.options.speed)
                                        this.moveTo(j);
                                        return false
                                    }
                                }
                            }
                            if (indexTo > step) step = indexTo;
                        }

                    },
                    onChangeEnd: function () {

                    },
                    onInit: function (tabs) {
                        attachFormValidator(tabs.$element.find("[data-constraints]"));

                        $('.checkout-step-btn').on("click", function (e) {
                            e.preventDefault();
                            var index = this.getAttribute("data-index-to"),
                                inputs = tabs.$content.find(".rd-material-tab").eq(index - 1).find("[data-constraints]");

                            if (isValidated(inputs)) {
                                tabs.setContentTransition(tabs, tabs.options.speed);
                                tabs.moveTo(parseInt(index));
                                if (index > step) step = index;
                            }
                        });
                    }
                }
            });
        }
    }


    // Material Parallax
    if (plugins.materialParallax.length) {
        if (!isNoviBuilder && !isIE && !isMobile) {
            plugins.materialParallax.parallax();

            // heavy pages fix
            $window.on('load', function () {
                setTimeout(function () {
                    $window.scroll();
                }, 500);
            });
        } else {
            for (var i = 0; i < plugins.materialParallax.length; i++) {
                var parallax = $(plugins.materialParallax[i]),
                    imgPath = parallax.data("parallax-img");

                parallax.css({
                    "background-image": 'url(' + imgPath + ')',
                    "background-size": "cover"
                });
            }
        }
    }

    /**
     * Highcharts
     * @description Enables Highcharts plugin
     */
    if (plugins.higCharts.charts.length) {
        var i,
            detailChart,
            masterChart;

        for (i = 0; i < plugins.higCharts.charts.length; i++) {
            var higchartsItem = $(plugins.higCharts.charts[i]),
                higChartsItemObject = parseJSONObject(higchartsItem, 'data-graph-object');

            if (!higchartsItem.attr('data-parent-chart') && !higchartsItem.attr('data-child-chart')) {
                higchartsItem.highcharts(
                    higChartsItemObject
                );
            } else {
                if (higchartsItem.attr('data-child-chart')) {
                    var childGraph = higchartsItem.attr('data-child-chart'),
                        higChartsChildObject = parseJSONObject(childGraph, 'data-graph-object');

                    masterChart = higchartsItem.highcharts(
                        higChartsItemObject, function () {
                            detailChart = $(childGraph).highcharts(
                                higChartsChildObject
                            ).highcharts();
                        }
                    ).highcharts();
                }
            }
        }
    }

    /**
     * Highcharts
     * @description Enables legends for highcharts plugin
     */
    if (plugins.higCharts.legend.length) {
        var i, j;

        for (i = 0; i < plugins.higCharts.legend.length; i++) {
            var higchartsLegend = plugins.higCharts.legend[i],
                legendId = $(higchartsLegend).attr('data-chart-id'),
                legendItems = $(higchartsLegend).find('.legend-item');

            for (j = 0; j < legendItems.length; j++) {
                var legendItem = $(legendItems[j]),
                    itemId = legendItem.attr('data-chart-id'),
                    legend = $(legendId).highcharts().series[itemId],
                    legendName = legend.name,
                    legendObj;

                if (legendItem.is('input')) {
                    if (legend.visible) {
                        legendItem.prop('checked', true);
                    } else {
                        legendItem.prop('checked', false);
                    }
                }

                legendItem.html(legendName);
                legendObj = {
                    legendItem: legendItem,
                    legend: legend
                };

                // assign click handler which toggles legend data
                legendItem.on('click', $.proxy(function (e) {
                    var _this = this;

                    if (_this.legendItem.attr('href')) {
                        e.preventDefault();
                    }
                    if (_this.legend.visible) {
                        _this.legend.hide();
                        _this.legendItem.toggleClass('active');
                    } else {
                        _this.legend.show();
                        _this.legendItem.toggleClass('active');
                    }
                }, legendObj));
            }
        }
    }

    /**
     * D3 Charts
     * @description Enables D3 Charts plugin
     */
    if (plugins.d3Charts.length) {
        var i;

        for (i = 0; i < plugins.d3Charts.length; i++) {
            var d3ChartsItem = $(plugins.d3Charts[i]),
                d3ChartItemObject = parseJSONObject(d3ChartsItem, 'data-graph-object');
            c3ChartsArray.push(c3.generate(d3ChartItemObject));
        }
    }

    /**
     * Flot Charts
     * @description Enables Flot Charts plugin
     */
    if (plugins.flotCharts.length) {
        var i;

        for (i = 0; i < plugins.flotCharts.length; i++) {
            var flotChartsItem = plugins.flotCharts[i],
                flotChartItemObject = parseJSONObject(flotChartsItem, 'data-graph-object'),
                gridObject = parseJSONObject(flotChartsItem, 'data-grid-object');

            $.plot(flotChartsItem, flotChartItemObject, gridObject);
        }
    }

    /**
     * Gallery RD Material Tabs
     */
    if (plugins.galleryRDTabs.length) {
        var uniqueRandoms = [];
        var timer = false,
            timer2 = false;
        plugins.galleryRDTabs.RDMaterialTabs({
            responsive: {
                0: {
                    items: 3
                },
                768: {
                    margin: 50
                },
                992: {
                    margin: 100,
                    items: 4
                },
                1200: {
                    items: 5
                },
                1600: {
                    items: 6
                }
            },
            callbacks: {
                onInit: function () {
                    plugins.galleryRDTabs.addClass('loaded');
                    if ($html.hasClass('desktop')) {
                        makeVisible(plugins.galleryRDTabs.find('.rd-material-tab:first-child .image'));
                    }
                },
                onChangeStart: function () {
                    if ($html.hasClass('desktop')) {
                        makeInvisible();
                    }
                },
                onChangeEnd: function () {
                    if ($html.hasClass('desktop')) {
                        makeVisible(plugins.galleryRDTabs.find('.rd-material-tab-active .image'));
                    }
                }
            }
        });
        $window.trigger("resize");
    }


});

